import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/services/base-data.service';
import { HomeReviewReqBody, Reviews, Store } from '../models/reviews.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  private apiStoreList = 'api/looksup/getStoreList';

  private apiReviews = "api/store/getRatings";
  constructor(
    private baseDataService: BaseDataService
  ) { }


  getStoreList(): Observable<Store[]>{
    return this.baseDataService.makeGetCall(`${this.apiStoreList}`);
  }

  getReviewsByStore(homereqBody:HomeReviewReqBody): Observable<Reviews>{
    // let queryString = `storeId=${storeId}&page=${page}`;
    return this.baseDataService.makePostCall(`${this.apiReviews}`,homereqBody );
  }



}
