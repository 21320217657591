import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { OrderService } from 'src/app/home/orders/services/order.service';
import { GlobalService } from 'src/app/helpers/services/global.service';
import { Store } from 'src/app/home/reports/models/reports.model';
import { ReviewsService } from 'src/app/home/reviews/services/reviews.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj: any = null;
  username: string;
  isChainUser: string;
  chainName: string;
  storeName: string;
  sectorCode: string;
  phoneNo:string;
  // stores: any[];
  storeList: Store[]=[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private globalService: GlobalService,
    private reviewService:ReviewsService
  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ['admin', 'teacher', 'student'];
        const currenturl = event.url.split('?')[0];
        const firstString = currenturl.split('/').slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = currenturl.split('/')[2];
          this.level2Menu = currenturl.split('/')[3];
        } else {
          this.level1Menu = currenturl.split('/')[1];
          this.level2Menu = currenturl.split('/')[2];
        }
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
//     this.getStoreList()
//     console.log('aaa');
// console.log(this.storeList);

  }

  @HostListener('window:resize', ['$event'])

  windowResizecall(event: any) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }


  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }


  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }


  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }

   getStoreList() {
     this.reviewService.getStoreList()
      //  .pipe(takeUntil(this.destroy))
   .subscribe((storeList) => {
      this.storeList = storeList;
      this.setExternalRoutes(this.storeList);

   })
    // console.log(this.storeList);
    // ROUTES = [];
    //  this.setExternalRoutes(this.storeList);
    // .subscribe((storeList) => {
    //   this.storeList =  storeList;
    //   // console.log(storeList);


    // });


  }


  ngOnInit() {
    this.getUserDetails();
    this.getStoreList();
    // console.log(this.storeList);

    // if(this.sectorCode == 'FOOD'){
    //   let menuPath = {
    //     path: 'home/menu',
    //     title: 'Menu Management',
    //     moduleName: 'menu',
    //     iconType: 'material-icons-two-tone',
    //     icon: 'restaurant_menu',
    //     class: '',
    //     groupTitle: false,
    //     badge: '',
    //     badgeClass: '',
    //     role: ['All'],
    //     submenu: [],
    //     queryParams:{type:1}
    //   }
    //   ROUTES.splice(4, 0, menuPath);
    // }else if(this.sectorCode != 'FOOD' && this.sectorCode != ""){
    //   let productPath = {
    //     path: 'home/product',
    //     title: 'Product Management',
    //     moduleName: 'product',
    //     iconType: 'material-icons-two-tone',
    //     icon: 'shopping_cart',
    //     class: '',
    //     groupTitle: false,
    //     badge: '',
    //     badgeClass: '',
    //     role: ['All'],
    //     submenu: [],
    //     queryParams:{type:1}
    //   }
    //   ROUTES.splice(4, 0, productPath);
    // }




    // if(this.storeName != ""){
    //   if(this.sectorCode == 'FOOD'){
    //     let menuPath = {
    //       path: 'home/menu',
    //       title: 'Menu Management',
    //       moduleName: 'menu',
    //       iconType: 'material-icons-two-tone',
    //       icon: 'restaurant_menu',
    //       class: '',
    //       groupTitle: false,
    //       badge: '',
    //       badgeClass: '',
    //       role: ['All'],
    //       submenu: [],
    //       queryParams:{type:1}
    //     }
    //     ROUTES.splice(4, 0, menuPath);
    //   }else{
    //     let productPath = {
    //       path: 'home/product',
    //       title: 'Product Management',
    //       moduleName: 'product',
    //       iconType: 'material-icons-two-tone',
    //       icon: 'shopping_cart',
    //       class: '',
    //       groupTitle: false,
    //       badge: '',
    //       badgeClass: '',
    //       role: ['All'],
    //       submenu: [],
    //       queryParams:{type:1}
    //     }
    //     ROUTES.splice(4, 0, productPath);
    //   }



    // }

    if (this.authService.currentUserValue) {
      const userRole = 'Admin';

      this.sidebarItems = ROUTES.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      );

    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }





  getUserDetails() {
    this.username = JSON.parse(localStorage.getItem('firstname'));
    this.isChainUser = JSON.parse(localStorage.getItem('isChainUser'));
    this.chainName = JSON.parse(localStorage.getItem('chainName'));
    this.storeName = JSON.parse(localStorage.getItem('storeName'));
    this.sectorCode = JSON.parse(localStorage.getItem('sectorCode'));
    this.phoneNo = JSON.parse(localStorage.getItem('userName'));
  }


  ngOnDestroy() {
    this.routerObj.unsubscribe();
    ROUTES.splice(4, 1);
    this.sidebarItems = ROUTES;
    // if (this.storeName != "") {
    // }
  }


  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }


  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }


  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }


  checkStatuForResize(firstTime: any) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }


  mouseHover(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }


  mouseOut(e: any) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }


  logout() {
    this.authService.logout();
  }

  async setExternalRoutes(storeList: Store[]) {
    // console.log(storeList[0]);

    if (storeList.length>0) {


      const store = storeList[0];


      if (store.SectorCode == 'FOOD') {
        let menuPath = {
          path: 'home/menu',
          title: 'Menu Management',
          moduleName: 'menu',
          iconType: 'material-icons-two-tone',
          icon: 'restaurant_menu',
          class: '',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: ['All'],
          submenu: [],
          queryParams: { type: 0, storeId: store.Id,sectorId:store.SectorId }
        }
        ROUTES.splice(4, 0, menuPath);
      } else if (store.SectorCode != 'FOOD' && store.SectorCode != "") {
        // console.log(storeList[0].SectorCode);
        let productPath = {
          path: 'home/product',
          title: 'Menu Management',
          moduleName: 'product',
          iconType: 'material-icons-two-tone',
          icon: 'shopping_cart',
          class: '',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          role: ['All'],
          submenu: [],
          queryParams: { type: 0, storeId: store.Id,sectorId:store.SectorId }
        }
        ROUTES.splice(4, 0, productPath);
      }
      this.sidebarItems = ROUTES;
      //console.log(ROUTES);

    }
  }
  ngAfterViewInit() {
    // this.getStoreList();


  }
}
