import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/services/global.service';
import { Store } from 'src/app/home/dashboard/models/dashboard.model';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  constructor(private globalService : GlobalService) {}
  storeList: Store[];
  ngOnInit(): void { }

  getStoreList() {
    this.globalService.getStoreListLooksup()
     //  .pipe(takeUntil(this.destroy))
  .subscribe((storeList) => {
     this.storeList = storeList;
    //  this.setExternalRoutes(this.storeList);

  })


 }
}
