import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/services/global.service';
import { ResetPasswordRequestBody } from '../models/reset-password.model';
import { ResetPasswordService } from '../services/reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  resetPasswordForm: FormGroup;
  resetPasswordRequestBody: ResetPasswordRequestBody;

  hidePassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;

  constructor(
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.createPasswordResetForm();
  }

  createPasswordResetForm(){
    this.resetPasswordForm = this.fb.group({
      OldPassword:      ['', Validators.required] ,
      Password:         ['', Validators.required],
      ConfirmPassword:  ['', Validators.required],
    })
  }


  resetPassword(formData: any){
    this.setRequestBody(formData);
    this.resetPasswordInDb();
  }


  setRequestBody(formData: any){
    this.resetPasswordRequestBody = {
      OldPassword:      formData.OldPassword,
      Password:         formData.Password,
      ConfirmPassword:  formData.ConfirmPassword,
    }
  }


  resetPasswordInDb(){
    this.resetPasswordService.resetPassword(this.resetPasswordRequestBody)
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        if(res == 'SUCCESS'){
          this.changesOnSuccess();
        }
      })
  }


  changesOnSuccess(){
    this.globalService.showSuccess('Password changed Successfully !');
    this.close();
  }


  close(){
    this.dialogRef.close();
  }

}
