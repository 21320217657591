import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/services/base-data.service';
import { ResetPasswordRequestBody } from '../models/reset-password.model';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private apiResetPasswordUrl = '/api/profile/changePassword';

  constructor(
    private baseDataService: BaseDataService
  ) { }


  resetPassword(resetPasswordRequestBody: ResetPasswordRequestBody):Observable<any>{
    return this.baseDataService.makePostCall(`${this.apiResetPasswordUrl}`, resetPasswordRequestBody)
  }
}
