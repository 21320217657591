import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(
    private authenticationService: AuthService,
    private toastr: ToastrService
  ) { }

  handleError(error: HttpErrorResponse){
    let status = error.status;

    if(status === 400){
      if(error.error.error == 'invalid_user'){
        Swal.fire({
          icon: 'error',
          text: 'No stores assigned for you. Contact the administration !',
        })
      }else if(error.error.error == 'invalid_grant'){
        Swal.fire({
          icon: 'error',
          text: error.error.error_description,
        })
      }else if(error.message.includes('/changePassword')){
        const ModelState = error.error.ModelState
      
        if(typeof(ModelState['model.ConfirmPassword']) != 'undefined'){
          ModelState['model.ConfirmPassword'].forEach(err => {
              this.showError(err)
          });
        }

        if(typeof(ModelState['model.Password']) != 'undefined'){
          ModelState['model.Password'].forEach(err => {
              this.showError(err)
          });
        }

        if(typeof(ModelState[""]) != 'undefined'){
          ModelState[""].forEach(err => {
              this.showError(err)
          });
        }
      }else{
        this.showError(error.error.Message)
      }
    }
    else if(status === 401){
      // auto logout if 401 response returned from api
      this.authenticationService.logout();
      location.reload();
    }else if(status === 500){
      this.showError('Server Error')
    }else{
      this.showError('Something went wrong! Try Again')
    }
  }


  showError(error: string) {
    this.toastr.error(error, 'Error!' , {
      timeOut: 3000
    });
  }
}
