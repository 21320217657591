
  <div class="header">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <h5 class="title">Change Password</h5>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <div class="btn-list">
          <mat-icon (click)="close()" class="close-button">close</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <hr class="line">

   <div class="card">
     <div class="body">
      <form [formGroup]="resetPasswordForm">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-label>Current Password<span class="required-star">*</span></mat-label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="OldPassword"  matInput placeholder="Enter Current Password" required>
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hidePassword = !hidePassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error *ngIf="resetPasswordForm.get('OldPassword').hasError('required') || resetPasswordForm.get('OldPassword').errors?.required">
                Old Password is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-label>New Password<span class="required-star">*</span></mat-label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="Password"   matInput placeholder="Enter New Password" required>
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hideNewPassword = !hideNewPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPassword">
                <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error *ngIf="resetPasswordForm.get('Password').hasError('required') || resetPasswordForm.get('Password').errors?.required">
                New Password is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-label>Confirm Password<span class="required-star">*</span></mat-label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="ConfirmPassword"  matInput placeholder="Re Enter the New Password" required>
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error *ngIf="resetPasswordForm.get('ConfirmPassword').hasError('required') || resetPasswordForm.get('ConfirmPassword').errors?.required">
                Confirm Password is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
   </div>
  </div>

  <hr style="border: 1px solid">

  <div class="modal-footer">
    <button mat-raised-button color="primary" (click)="resetPassword(resetPasswordForm.value)" [disabled]="!resetPasswordForm.valid">Change Password</button> &nbsp; &nbsp;
    <button mat-raised-button class="btn-warning" (click)="close()">Cancel</button>
  </div>



